<template>
  <div class="content-layout">
    <div class="left-layout">
      <a-card title="产品目录" class="tree-card">
        <a-tree @select="onSelect" :selected-keys="selectedKeys" default-expand-all>
          <a-tree-node v-for="tree in treeData" :key="tree.id" :title="tree.name">
            <a-tree-node
              v-for="productClass in tree.productClasses"
              :key="productClass.id"
              :title="productClass.name"
            ></a-tree-node>
          </a-tree-node>
        </a-tree>
      </a-card>
    </div>
    <div class="right-layout">
      <a-card>
        <div class="product-sub-class-operations">
          <div class="left-btn">
            <a-button
              v-if="$hasPermissions(['rcs:product_sub_class:create'])"
              type="primary"
              class="add-btn"
              @click="handleCreator"
              >新增</a-button
            >
            <template v-if="$hasPermissions(['rcs:product_sub_class:update'])">
              <a-button type="primary" class="add-btn" :disabled="!selectedKeys[0]" @click="handleSort" v-if="!isOnSort"
                >排序</a-button
              >
              <a-button type="primary" class="add-btn" @click="saveSort" v-else>保存</a-button>
            </template>
          </div>
          <div class="data-filter">
            <a-select
              v-model="languageSelect"
              placeholder="请选择翻译语言"
              showSearch
              optionFilterProp="children"
              @change="loadedPageData"
              :style="{ width: '8vw', marginRight: '10px' }"
            >
              <a-select-option v-for="(language, key) in languageOptions" :key="key" :value="language.value">
                {{ language.label }}
              </a-select-option>
            </a-select>
            <a-select v-model="filterSelected" style="width: 120px">
              <a-icon slot="suffixIcon" type="smile" />
              <a-select-option v-for="filter in filterOptions" :key="filter.value" :value="filter.value">
                {{ filter.label }}
              </a-select-option>
            </a-select>
            <a-input v-model="searchKeyword" class="search-keyword" clearable @keyup.enter.native="doSearch"></a-input>
            <a-button class="search-btn" type="primary" @click="doSearch()">搜索</a-button>
          </div>
        </div>
        <a-table
          size="middle"
          :pagination="false"
          :columns="$constRepository.productSubClass.columns"
          :data-source="tableDate"
          class="product-sub-class-table"
          :customRow="isOnSort ? customRow : null"
          rowKey="id"
        >
          <template slot="kind" slot-scope="scope">
            {{ $constRepository.productSubClass.productSubClassKind(scope) }}
          </template>
          <template slot="type" slot-scope="scope">
            {{ $constRepository.productSubClass.productSubClassType(scope) }}
          </template>
          <template slot="regionIdentifiers" slot-scope="scope">
            <span v-if="scope">
              {{ scope.join(',') }}
            </span>
            <span v-else>-</span>
          </template>
          <span slot="action" slot-scope="scope">
            <a-button
              v-if="
                languageSelect === $constRepository.language.FILTER_OPTION_ZH_CN.value &&
                $hasPermissions(['rcs:product_sub_class:update'])
              "
              class="green-btn"
              ghost
              @click="handleEdit(scope)"
              >编辑</a-button
            >
            <a-divider v-if="languageSelect === $constRepository.language.FILTER_OPTION_ZH_CN.value" type="vertical" />
            <a-button
              v-if="$hasPermissions(['rcs:product_sub_class:get'])"
              class="green-btn"
              ghost
              @click="handleDetail(scope)"
              >详情</a-button
            >
            <a-divider type="vertical" />
            <a-button v-if="$hasPermissions(['rcs:product_sub_class:delete'])" class="red-btn" ghost>
              <a-popconfirm okText="是" cancelText="否" title="是否删除产品小类?" @confirm="() => onDelete(scope)">
                <a href="javascript:;">删除</a>
              </a-popconfirm>
            </a-button>
          </span>
        </a-table>
        <div class="pagination" v-if="!isOnSort">
          <a-pagination
            :current="pageInfo.page"
            :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
            show-quick-jumper
            show-size-changer
            :total="pageInfo.totalElements"
            @showSizeChange="onShowSizeChange"
            @change="onChange"
            :pageSizeOptions="['10', '20', '30', '40']"
          />
          <br />
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep';
// import lodash from 'lodash';

export default {
  name: 'ProductSubClassManagement',
  components: {},
  async activated() {
    await this.listProductCategories();
    await this.listProductClasses();
    this.loadedPageData();
  },
  data() {
    return {
      languageSelect: this.$constRepository.language.FILTER_OPTION_ZH_CN.value,
      languageOptions: this.$constRepository.language.FILTER_OPTIONS,
      selectedKeys: [],
      filterSelected: this.$constRepository.productSubClass.FILTER_OPTION_PRODUCT_SUB_CLASS_SUB_CLASS.value,
      filterOptions: this.$constRepository.productSubClass.FILTER_OPTIONS,
      searchKeyword: '',
      requestParams: {},
      tableDate: [],
      treeData: [],
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      orderIndex: undefined,
      isOnSort: false,
    };
  },
  methods: {
    customRow(record, index) {
      return {
        // FIXME: draggable： true 不生效还不晓得是什么原因，先使用鼠标移入事件设置目标行的draggable属性
        props: {
          // draggable: 'true'
        },
        style: {
          cursor: 'pointer',
        },
        on: {
          // 鼠标移入
          mouseenter: (event) => {
            // 兼容IE
            const ev = event || window.event;
            ev.target.draggable = true;
          },
          // 开始拖拽
          dragstart: (event) => {
            // 兼容IE
            const ev = event || window.event;
            // 阻止冒泡
            ev.stopPropagation();
            // 得到源目标数据
            this.sourceObj = record;
            this.orderIndex = index;
          },
          // 拖动元素经过的元素
          dragover: (event) => {
            // 兼容 IE
            const ev = event || window.event;
            // 阻止默认行为
            ev.preventDefault();
          },
          // 鼠标松开
          drop: (event) => {
            // 兼容IE
            const ev = event || window.event;
            // 阻止冒泡
            ev.stopPropagation();
            // 得到目标数据
            this.targetObj = record;
            const tableDate = clonedeep(this.tableDate);
            const selectDate = clonedeep(tableDate[this.orderIndex]);
            tableDate.splice(this.orderIndex, 1);
            tableDate.splice(index, 0, selectDate);
            this.tableDate = tableDate;
          },
        },
      };
    },
    async listProductSubClasses() {
      this.isOnSort = false;
      const headers = {
        'accept-language': this.languageSelect,
      };
      const params = {
        productCategoryId: this.requestParams.productCategoryId,
        productClassId: this.requestParams.productClassId,
        productCategoryName: this.requestParams.productCategoryName,
        productClassName: this.requestParams.productClassName,
        productSubClassName: this.requestParams.productSubClassName,
        productId: this.requestParams.productId,
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
      };
      const response = await this.$apiManager.productSubClass.getProductSubClasses(params, headers);
      this.tableDate = response.data.elements;
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    onSelect(selectedKeys) {
      this.selectedKeys = selectedKeys;
      this.$set(this.requestParams, 'productCategoryId', undefined);
      this.$set(this.requestParams, 'productClassId', undefined);
      if (selectedKeys.length) {
        if (this.treeData.find((productCategory) => productCategory.id === selectedKeys[0])) {
          this.$set(this.requestParams, 'productCategoryId', selectedKeys[0]);
        } else {
          this.$set(this.requestParams, 'productClassId', selectedKeys[0]);
        }
      }
      this.loadedPageData();
    },
    async listProductCategories() {
      const params = { unpaged: true };
      const response = await this.$apiManager.productCategories.getProductCategories(params);
      this.treeData = response.data.elements;
    },
    async listProductClasses() {
      const params = { unpaged: true };
      const response = await this.$apiManager.productClasses.getProductClasses(params);
      if (response.data) {
        this.treeData.forEach((productCategory) => {
          this.$set(productCategory, 'productClasses', []);
          response.data.elements.forEach((productClass) => {
            if (productClass.productCategoryId === productCategory.id) {
              productCategory.productClasses.push(productClass);
            }
          });
        });
      }
    },
    processSearchKeyword() {
      this.$set(this.requestParams, 'productCategoryName', undefined);
      this.$set(this.requestParams, 'productClassName', undefined);
      this.$set(this.requestParams, 'productSubClassName', undefined);
      this.$set(this.requestParams, 'productId', undefined);
      switch (this.filterSelected) {
        case this.$constRepository.productSubClass.FILTER_OPTION_PRODUCT_SUB_CLASS_SUB_CLASS.value:
          this.$set(this.requestParams, 'productSubClassName', this.searchKeyword);
          break;
        case this.$constRepository.productSubClass.FILTER_OPTION_PRODUCT_SUB_CLASS_PRODUCT_CATEGORY.value:
          this.$set(this.requestParams, 'productCategoryName', this.searchKeyword);
          break;
        case this.$constRepository.productSubClass.FILTER_OPTION_PRODUCT_SUB_CLASS_PRODUCT_CLASS.value:
          this.$set(this.requestParams, 'productClassName', this.searchKeyword);
          break;
        case this.$constRepository.productSubClass.FILTER_OPTION_PRODUCT_SUB_CLASS_PRODUCT_PRODUCT_ID.value:
          this.$set(this.requestParams, 'productId', this.searchKeyword);
          break;
        default:
          break;
      }
    },
    doSearch() {
      this.pageInfo.page = 1;
      this.loadedPageData();
    },
    async loadedPageData() {
      this.processSearchKeyword();
      await this.listProductSubClasses();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        await this.listProductSubClasses();
      }
    },
    handleCreator() {
      this.$router.push({ name: 'productSubClassCreator' });
    },
    handleEdit(record) {
      this.$router.push({ name: 'productSubClassEditor', params: { id: record.id } });
    },
    handleDetail(record) {
      this.$router.push({ name: 'productSubClassDetail', params: { id: record.id } });
    },
    async onDelete(record) {
      this.$apiManager.productSubClass.deleteProductSubClass(record.id).then(() => {
        this.$message.success('删除成功');
        this.doSearch();
      });
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
    async handleSort() {
      const headers = {
        'accept-language': this.languageSelect,
      };
      const params = {
        productCategoryId: this.requestParams.productCategoryId,
        productClassId: this.requestParams.productClassId,
        productCategoryName: this.requestParams.productCategoryName,
        productClassName: this.requestParams.productClassName,
        productSubClassName: this.requestParams.productSubClassName,
        unpaged: true,
      };
      const response = await this.$apiManager.productSubClass.getProductSubClasses(params, headers);
      this.tableDate = response.data.elements;
      this.isOnSort = !this.isOnSort;
    },
    async saveSort() {
      this.tableDate.forEach((value, index) => {
        // eslint-disable-next-line no-param-reassign
        value.sort = index;
      });
      this.$apiManager.productSubClass.updateSort(this.tableDate).then(() => {
        this.$message.success('排序成功');
        this.doSearch();
      });
      this.isOnSort = !this.isOnSort;
    },
  },
};
</script>

<style lang="less" scoped>
.content-layout {
  height: auto;
  display: flex;
  justify-content: space-between;
  .left-layout {
    margin-left: 1%;
    width: 15%;
    height: auto;
    .tree-card {
      height: 100%;
    }
  }
  .right-layout {
    width: 80%;
    height: auto;
    float: right;
    margin-right: 1%;
  }
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.product-sub-class-operations {
  display: flex;
  justify-content: space-between;
  .left-btn {
    flex: auto;
  }
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      margin: 10px 0px;
      margin-left: 3%;
    }
  }
  .product-sub-class-table {
    margin-top: 2%;
  }
}
.pagination {
  margin-top: 3%;
}
</style>
